import { authConnector } from "@/axios.js"

export default {
    async getUserProfile(redirect_uri, queryCode) {
        try {
            let _data = JSON.parse(`{}`)
            _data["redirect_uri"] = `${redirect_uri}`
            _data[`code`] = `${queryCode}`
            let response = await authConnector.post(`/auth/profile`, _data)
            return response["data"]
        } catch (error) {
            console.log(`error `, error.message)
            return false
        }
    }
}