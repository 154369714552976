import axios from "axios"
const basicAuthSaas = "Basic " + btoa(process.env.VUE_APP_SAAS_USER + ":" + process.env.VUE_APP_SASS_PASSWORD)
const basicAuthEzBooking = "Basic " + btoa(process.env.VUE_APP_EZBOOKING_API_USER + ":" + process.env.VUE_APP_EZBOOKING_API_PASSWORD)
export const SUPERSAAS = axios.create({
    baseURL: "https://www.supersaas.com/",
    headers: {
        Authorization: basicAuthSaas,
    },
})

export const authConnector = axios.create({
    baseURL: `https://auth-tlic.tlic.cmu.ac.th/ezbooking`,
    headers: {},
})

export const ezBookingApi = axios.create({
    baseURL: `https://api-ezbooking.tlic.cmu.ac.th`,
    headers: {
        Authorization: basicAuthEzBooking,
    },
})